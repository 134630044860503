.vt_ms_skyline{
  background-image: url("../Images/skyline.png");
  background-size: contain;
  background-repeat: no-repeat, repeat;
  background-position: bottom;
  min-height: 550px;
}

.vt_ms_message{
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
  text-align:center;
  text-transform: uppercase;
  font-size: 1.25rem;
}

.tvis_mainscreen_social{
  width:100%;
  text-align: center;
  padding-top:7rem;
  padding-bottom:7rem;
}

.tvis_mainscreen_social_message{
  margin-top:2rem;
  padding-left:20%;
  padding-right:20%;
  font-size: 20px;;
}

