.dashboard_main_separator{
  margin-bottom: 2rem;
}

.dashboard_header_row{
  margin-top:0.5rem;
  margin-bottom:1.5rem;
}

.dashboard_exportbutton{
  margin-bottom:0.5rem;
}

/*WARNINGS*/
.dashboard_warning_sign{
  width:100%;
  color:var(--white);
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin: 0.5rem 0rem;
  font-weight: 700;
  border-style: solid;
  border-width: 2px;
  border-color:var(--primary);
}

/*GRAPHS*/
.dashboard_graphs_margins{
  border:solid;
  border-width:1px;
  border-color:var(--primary);
  border-radius:0.25rem;
  margin-bottom:1rem;
  padding:0.1rem;
}

.dashboard_graphs_card{
  text-align: center;
  margin-top:1rem;
  margin-bottom:1.5rem;
  padding:0.1rem;
  border:solid;
  border-width:1px;
  border-color:var(--primary);
  border-radius:0.25rem;
}

.dashboard_graphs_note{
  font-size: 8px;
  text-align: center;
  width:100%;
}

/* TABLES*/
.table_row_special_1 {
  background-color: var(--lightaccent);
  color:var(--darktext);
}

.table_row_special_2 {
  background-color: var(--darkshade);
  color:var(--lighttext);
}