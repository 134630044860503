.t_public_login{
  background-color: var(--darkaccent);
}

.t_public_logos{
  text-align: center;
  max-width:25rem;
  max-height: 15rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top:1rem;
  margin-bottom: 1rem;
}

.t_public_second_row{
  margin-top: 1.5rem;
  margin-bottom:1.5rem;
}

@media only screen and (max-width: 600px) {
  .t_public_col2 {
    margin-top:2rem;
  }
}
@media only screen and (min-width: 601px) {
  .t_public_col2 {
    margin-top:2.5rem;
  }
}