.subscription_modal{
  width:90%;
  background-color: aqua;
}

.admin_main{
  margin-top:.5rem;
  margin-bottom:.5rem;
}

/*****Accounts Lists*/
.admin_al_filter_col{
  margin-top: .5rem;
  margin-bottom:1rem;

}

.admin_al_lists_rows{
  padding-top: .15rem;
  padding-bottom: .15rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--darkaccent);
}

.admin_al_user_cols{
  margin-bottom: .5rem;
}

.admin_al_sub_main_cols{
  margin-top: 1rem;
}

.admin_al_sub_cols{
  margin-bottom: 1rem;
}