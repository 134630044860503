:root{
  --margin_setter: 3px;
}


/**VERTICAL ALLIGN*/
.grid_middle{
  align-items: center;
  display:flex;
}
/**/

/*HORIZONTAL ALLIGN*/
.grid_center{
  align-content: center;
}
/**/

.grid_container{
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

/***ROWS***/
.grid_row{
  box-sizing: border-box;
  display:flex;
  flex-wrap:wrap;
  margin-left: -3px;
  margin-right: -3px;
}

.grid_row_col_direction{
flex-direction: column;
}

/***COLS***/
.grid_col{
  flex: 0 0 auto;
  padding-left: var(--margin_setter);
  padding-right: var(--margin_setter);
}
  .xs1{
    flex-basis: 8.33333333%;
    width: 8.33333333%;
  }
  .xs2{
    flex-basis: 16.66666666%;
    width: 16.66666666%;
  }
  .xs3{
    flex-basis: 25%;
    width: 25%;
  }
  .xs4{
    flex-basis: 33.33333333%;
    width: 33.33333333%;
  }
  .xs5{
    flex-basis: 41.66666666%;
    width: 41.66666666%;
  }
  .xs6{
    flex-basis: 50.0%;
    width: 50.0%;
  }
  .xs7{
    flex-basis: 58.33333333%;
    width: 58.33333333%;
  }
  .xs8{
    flex-basis: 66.66666666%;
    width: 66.66666666%;
  }
  .xs9{
    flex-basis: 75.0%;
    width: 75.0%;
  }
  .xs10{
    flex-basis: 83.33333333%;
    width: 83.33333333%;
  }
  .xs11{
    flex-basis: 91.66666666%;
    width: 91.66666666%;
  }
  .xs12{
    flex-basis: 100.0%;
    width: 100.0%;
  }

@media only screen and (min-width: 576px){
  .sm1{
    flex-basis: 8.33333333%;
    width: 8.33333333%;
  }
  .sm2{
    flex-basis: 16.66666666%;
    width: 16.66666666%;
  }
  .sm3{
    flex-basis: 25.0%;
    width: 25.0%;
  }
  .sm4{
    flex-basis: 33.33333333%;
    width: 33.66666666%;
  }
  .sm5{
    flex-basis: 41.66666666%;
    width: 41.66666666%;
  }
  .sm6{
    flex-basis: 50.0%;
    width: 50.0%;
  }
  .sm7{
    flex-basis: 58.33333333%;
    width: 58.33333333%;
  }
  .sm8{
    flex-basis: 66.66666666%;
    width: 66.66666666%;
  }
  .sm9{
    flex-basis: 75.0%;
    width: 75.0%;
  }
  .sm10{
    flex-basis: 83.33333333%;
    width: 83.33333333%;
  }
  .sm11{
    flex-basis: 91.66666666%;
    width: 91.66666666%;
  }
  .sm12{
    flex-basis: 100.0%;
    width: 100.0%;
  }
}

@media only screen and (min-width: 768px){
  .md1{
    flex-basis: 8.33333333%;
    width: 8.33333333%;
  }
  .md2{
    flex-basis: 16.66666666%;
    width: 16.66666666%;
  }
  .md3{
    flex-basis: 25.0%;
    width: 25.0%;
  }
  .md4{
    flex-basis: 33.33333333%;
    width: 33.33333333%;
  }
  .md5{
    flex-basis: 41.66666666%;
    width: 41.66666666%;
  }
  .md6{
    flex-basis: 50.0%;
    width: 50.0%;
  }
  .md7{
    flex-basis: 58.33333333%;
    width: 58.33333333%;
  }
  .md8{
    flex-basis: 66.66666666%;
    width: 66.66666666%;
  }
  .md9{
    flex-basis: 75.0%;
    width: 75.0%;
  }
  .md10{
    flex-basis: 83.33333333%;
    width: 83.33333333%;
  }
  .md11{
    flex-basis: 91.66666666%;
    width: 91.66666666%;
  }
  .md12{
    flex-basis: 100.0%;
    width: 100.0%;
  }
}

@media only screen and (min-width: 992px) {
  .lg1{
    flex-basis: 8.33333333%;
    width: 8.33333333%;
  }
  .lg2{
    flex-basis: 16.66666666%;
    width: 16.66666666%;
  }
  .lg3{
    flex-basis: 25.0%;
    width: 25.0%;
  }
  .lg4{
    flex-basis: 33.33333333%;
    width: 33.66666666%;
  }
  .lg5{
    flex-basis: 41.66666666%;
    width: 41.66666666%;
  }
  .lg6{
    flex-basis: 50.0%;
    width: 50.0%;
  }
  .lg7{
    flex-basis: 58.33333333%;
    width: 58.33333333%;
  }
  .lg8{
    flex-basis: 66.66666666%;
    width: 66.66666666%;
  }
  .lg9{
    flex-basis: 75.0%;
    width: 75.0%;
  }
  .lg10{
    flex-basis: 83.33333333%;
    width: 83.33333333%;
  }
  .lg11{
    flex-basis: 91.66666666%;
    width: 91.66666666%;
  }
  .lg12{
    flex-basis: 100.0%;
    width: 100.0%;
  }
}

@media only screen and (min-width: 1200px) {
  .xl1{
    flex-basis: 8.33333333%;
    width: 8.33333333%;
  }
  .xl2{
    flex-basis: 16.66666666%;
    width: 16.66666666%;
  }
  .xl3{
    flex-basis: 25.0%;
    width: 25.0%;
  }
  .lxl4{
    flex-basis: 33.33333333%;
    width: 33.66666666%;
  }
  .xl5{
    flex-basis: 41.66666666%;
    width: 41.66666666%;
  }
  .xl6{
    flex-basis: 50.0%;
    width: 50.0%;
  }
  .xl7{
    flex-basis: 58.33333333%;
    width: 58.33333333%;
  }
  .xl8{
    flex-basis: 66.66666666%;
    width: 66.66666666%;
  }
  .xl9{
    flex-basis: 75.0%;
    width: 75.0%;
  }
  .xl10{
    flex-basis: 83.33333333%;
    width: 83.33333333%;
  }
  .xl11{
    flex-basis: 91.66666666%;
    width: 91.66666666%;
  }
  .xl12{
    flex-basis: 100.0%;
    width: 100.0%;
  }
}




