
/*COLABORATE AND VIEW NOTIFICATIONS*/
.main_screen_box1{
  margin: 1rem 0rem;
}


/*/MAINSCREEN*/
.main_screen_lc_img{
  max-width: 2rem;
  display:inline;
}

.main_screen_Welcome{
  font-size: 275%;
  font-weight: 100;
  line-height: 1;
  text-shadow: 2px 2px 5px var(--lightaccent);
}

.main_screen_lc_header{
  color:var(--darkshade);
  text-align: center;
  font-size: 4rem;
  font-weight: 100;
  margin-top:3rem;
  margin-bottom: 1rem;
}


.main_screen_lc_manuals{
  width:100%;
  background-color: white;
  margin-top:1rem;
  margin-bottom:1.5rem;
  text-align:center;
}

.main_screen_lc_section{
  margin-top:3rem;
}

.helpdesk_header{
  margin-top:1rem;
  margin-bottom:1rem;
}

.main_sreen_social{
  background-color: var(--primary);
  padding:2.5rem;
}

/****TEMPLATES*/
.main_screen_template_scard{
  color:white;
}

/****PARTNERSHIPS*/

.main_screen_partnerships{
  margin-bottom: 1rem;
}

/****PRICING*/
.pricing_group{
  width:100%;
  display:flex;
  flex-wrap: wrap;
}

.pricing_element{
  flex:1;
  background-color: var(--info);
  padding:1rem;
  margin:1rem;
  color:var(--lighttext);
  text-align: center;
}