.main_loader{
  display: flex;
  justify-content: center;
  width: 100%;
  padding:2.5% 15% 0% 15%;
  animation: show 1s linear;
}

.main_loader div{
  background-color:var(--darkshade);
  flex:1;
  margin-left:0.25rem;
  margin-right:0.25rem;
  border-radius: 0.25rem;
}

.main_loader div.subloader1{
  animation:grow 1s linear infinite;
  animation-delay:0.1s;
}

.main_loader div.subloader2{
  animation:grow 2s linear infinite;
  animation-delay:0.2s;
}

.main_loader div.subloader3{
  animation:grow 3s linear infinite;
  animation-delay:0.4s;
}
.main_loader div.subloader4{
  animation:grow 2s linear infinite;
  animation-delay:0.3s;
}

.main_loader div.subloader5{
  animation:grow1 1s linear infinite;
  animation-delay:-0.7s;
}

.main_loader div.subloader6{
  animation:grow 2s linear infinite;
  animation-delay:-0.8s;
}

.main_loader div.subloader7{
  animation:grow 2s linear infinite;
  animation-delay:-0.4s;
}

.main_loader div.subloader8{
  animation:grow2 8s linear infinite;
  animation-delay:-4s;
}

.main_loader div.subloader9{
  animation:grow 0.5s linear infinite;
  animation-delay:-0.2s;
}

.main_loader div.subloader10{
  animation:grow1 1s linear infinite;
  animation-delay:-0.1s;
}

.main_loader div.subloader11{
  animation:grow 2s linear infinite;
  animation-delay:-0.3s;
}

.main_loader div.subloader12{
  animation:grow 4s linear infinite;
  animation-delay:-0.2s;
}

.main_loader div.subloader13{
  animation:grow 4s linear infinite;
  animation-delay:0s;
}

.main_loader div.subloader14{
  animation:grow 1s linear infinite;
  animation-delay:-0.1s;
}

.main_loader div.subloader15{
  animation:grow1 1.5s linear infinite;
  animation-delay:-0.1s;
}

.main_loader div.subloader16{
  animation:grow1 0.5s linear infinite;
  animation-delay:-0.25s;
}

.main_loader div.subloader17{
  animation:grow2 1s linear infinite;
  animation-delay:0s;
}

.main_loader div.subloader18{
  animation:grow 2s linear infinite;
  animation-delay:0.1s;
}

.main_loader div.subloader19{
  animation:grow1 2s linear infinite;
  animation-delay:0.1s;
}

.main_loader div.subloader20{
  animation:grow 1s linear infinite;
  animation-delay:-0.1s;
}

@keyframes grow {
  0%,100% {
    height: 2rem;
    margin-top:8rem;
  }
  50% {
    height: 6rem;
    margin-top:4rem;
  }
}

@keyframes grow1 {
  0%,100% {
    height: 3rem;
    margin-top:7rem;
  }
  50% {
    height: 8rem;
    margin-top:2rem;
  }
}

@keyframes grow2 {
  0%,100% {
    height: 1rem;
    margin-top:9rem;
  }
  50% {
    height: 4rem;
    margin-top:6rem;
  }
}

@keyframes show {
  0%{
    opacity: 0.1;
  }
  100%{
    opacity: 1;
  }
}

.loader_message{
  width: 100%;
  text-align: center;
  color:var(--darkshade);
  font-weight: 900;
  font-size:4rem;
}