
.public_use{
background-color: var(--lightshade);
padding-top:1rem;
padding-bottom: 2rem; 
min-height: 100vh;
}

.public_language_buttons{
  flex:1;
}

.public_box{
  background-color: var(--primary);

}

.public_white_font{
  color:white;
}

.public_logo{
  width: 100%;
  height: auto;
  padding: 5% 25% 5% 25%;
}


.public_last_row_buttons{
  margin-top:1rem;
}

/*First pass */
.first_pass_caption{
  text-align: center;
  color: var(--lighttext);
}

.register_Welcome{
  font-size: 400%;
  font-weight: 100;
  line-height: 1;
  color:white;
  text-shadow: 2px 2px 5px var(--lightaccent);
  text-align: center;
}

/*ATIVATE DONE*/
.activate_div{
  text-align: center;
  color:white;
}