.tcf_public_login{
  background-color: var(--darkshade);
  background: linear-gradient(0deg, var(--darkaccent) 0%, var(--darkshade) 60%);
}

.tcf_public_logos{
  width:100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tcf_public_second_row{
  margin-top: 1.5rem;
  margin-bottom:1.5rem;
}