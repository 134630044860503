/***************GENERAL********/
form{
  width: 100%;
}

input,textarea{
  width: 100%;
  background: none;
  border:none;
  color:var(--darktext);
  padding:.5rem .15rem;
}

input:focus,textarea:focus{
  outline: none;
  font-weight: 700;
  box-shadow: 0 0 5 20 var(--darkaccent);
}

select{
  width: 100%;
  background: none;
  border:none;
  color:var(--darktext);
  padding-top:.5rem;
  padding-bottom:.5rem;
  padding-left: .5rem;
}

button{
  background: none;
	color: inherit;
	border: none;
  border-radius:0;
	padding: .75rem;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  text-transform:uppercase;
  -webkit-box-shadow: inset 0px 0px 0px 3px rgba(255,255,255,0.8);
  -moz-box-shadow: inset 0px 0px 0px 3px rgba(255,255,255,0.8);
  box-shadow: inset 0px 0px 0px 2px rgba(255,255,255,0.2);
}

button:hover{
  filter:brightness(1.2);
  font-weight: 900;
  transition:.5s;
}

button:active{
  filter:brightness(0.8);
  font-weight: 900;
  transition:.15s;
}

button:disabled{
  background-color: var(--lightaccent);
}


.support_input_group{
  margin-top: .75rem;
  margin-bottom: .75rem;
  border:solid;
  border-color: var(--darkaccent);
  border-width: 1px;
}

.support_input_group_simple{
  margin-top: .1rem;
  margin-bottom: .1rem;
  border:solid;
  border-color: var(--darkaccent);
  border-width: 1px;
}

.support_input_group_disabled{
  margin-top: .75rem;
  margin-bottom: .75rem;
  border:solid;
  border-color: var(--lightaccent);
  border-width: 1px;
  background-color: var(--lightshade);
}

.support_input_forms_vertical_label{
  color:var(--darktext);
  font-weight: 700;
  border-bottom:solid;
  border-color: var(--darkaccent);
  border-width: 1px;
  padding-top: .25rem;
  padding-bottom: .25rem;
  align-content: center;
  position:relative;
  display: flex;
  flex-wrap: wrap;
}

.support_input_forms_vertical_label_disabled{
  background-color: var(--lightaccent);
  color:var(--lighttext);
  padding-top: .25rem;
  padding-bottom: .25rem;
  align-content: center;
}

.support_input_forms_horizontal_label{
  color:var(--darktext);
  font-weight: 700;
  border-right:solid;
  border-color: var(--darkaccent);
  border-width: 1px;
  padding-top: .25rem;
  padding-bottom: .25rem;
  align-content: center;
}

.spt_equal_button_form_group{
  padding: 0.25rem;
  background-color: var(--darkaccent);
  color: var(--lighttext);
}


.support_non_editable_form_row{
  display: flex;
}

.support_non_editable_form_row_total{
  font-weight: 900;
  display: flex;
}

.support_non_editable_form_row_item{
  flex-basis: 0;
  flex-grow: 1;
  margin-left:0.1rem;
  margin-right:0.1rem;
  text-align: right;
}

.support_year_header {
  color: black;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.5rem;
  border-color: var(--darkaccent);
  border-style: solid;
  border-width: 3px;
  font-weight:700;
  text-align: center;
  font-size:16px;
}

.spt_year_header_div{
  background-color: var(--primary);
  padding-top: .2rem;
  padding-bottom: .2rem;
}

.support_year_header_div_distr{
  flex-basis: 0;
  flex-grow: 1;
  text-align: right;
  padding-right: .25rem;
}

.support_year_header_col_flex{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.support_input_forms_single_flat{
  margin-top:1rem;
  margin-bottom: 1rem;
  position:relative;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.support_observations{
  margin-top:1rem;
  margin-bottom: 1rem;
}

.support_selects{
  border:1px solid var(--primary);
  margin-top: .25rem;
}
.support_selects_tabs{
  margin-top: .25rem;
  margin-bottom:.25rem;
}

.support_selectable{
  border:1px solid var(--primary);
  margin-top: .25rem;
  margin-bottom:.25rem;
}







/*****--TOOGLE BUTTONS--*****/
.support_buttons_TB_group{
  margin-top: .75rem;
  margin-bottom: .75rem;
}
.support_buttons_TB_div{
  display: flex;
}

.support_buttons_TB_notice{
  padding:.25rem;
  font-size: smaller;
}

.support_buttons_TB_selected{
  animation:25s;
  background-color: var(--darkaccent);
  color:var(--lighttext);
  font-weight: 700;
  flex:1.25;

}

.support_buttons_TB_unselected{
  animation:25s;
  flex:1;
  background-color: var(--lightshade);
  color: var(--darktext);
}

.support_buttons_TB_button{
  border: none;
  padding-top: .5rem;
  padding-bottom: .5rem;
  text-align: center;
  text-decoration: none;
  box-shadow:none;
  border-style: solid;
  border-color: var(--darkaccent);
  border-width: 1px;
}

.support_buttons_TB_label{
  padding-left: .5rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-weight: 700;
  border-top:solid;
  border-right:solid;
  border-left: solid;
  border-color: var(--darkaccent);
  border-width: 1px;
  color:var(--darktext);
}


.spt_toobtn_stepper_wrapper{
  margin-top: auto;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.spt_toobtn_stepper_item{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: .5rem;
}

.spt_toobt_stepper_completed{
  background-color: var(--success);
}

.spt_toobtn_step_counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.support_buttons_TB_numberinput{
  border-left-style: solid;
  border-bottom-style: solid;
  border-right-style: solid;
  border-width: 1px;
  border-color:var(--darkshade);  
}


.spt_toobt_stepper_active_label{
  color: var(--primary);
}

.spt_toobt_stepper_unactive_label{
  color: var(--white);
}



/*****--LIST BUTTONS--*****/
/**GENERAL STUFF **/
.spt_regular_button{
  width: 100%;
  appearance: none;
  text-transform:uppercase;
  font-weight: 600;
  font-size: smaller;
}

.spt_regular_small{
  padding:.15rem;
}

.spt_regular_full_height{
  height: 100%;
}

.listbutton_regular{
  flex:1;
  width: 100%;
  margin:.1rem;
}


.spt_button_activate{
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: var(--darkaccent);
  cursor:pointer;
  margin-top: .15rem;
  margin-bottom: .15rem;
}

.spt_button_activate:hover{
  filter:brightness(1.2);
  font-weight: 900;
  transition:.5s;
}

.spt_button_activate:active{
  filter:brightness(0.8);
  font-weight: 900;
  transition:.15s;
}

.spt_button_activate_inside_active{
  padding:.25rem;
  width:50%;
  background-color:var(--success);
  color:var(--lighttext);
  right: -50%;
  position: relative;
  text-align: right;
  transition: right .5s, background-color .5s, text-align .5s;
}

.spt_button_activate_inside_not_active{
  padding:.25rem;
  width:50%;
  text-align: left;
  background-color:var(--danger);
  color:var(--lighttext);
  right:0px;
  position: relative;
  transition: right .5s, background-color .5s, text-align .5s;
}

.listbutton_regular_restricted_height{
  flex:1;
  width: 100%;
  min-width: 3rem;
  height:2.25rem;
  margin:.1rem;
  padding:.25rem;
}

.listbutton_regular_restricted_height_small{
  flex:1;
  width: 100%;
  min-width: 2rem;
  height:1.5rem;
  margin:.1rem;
  padding:.0rem;
}

.listbutton_regular_img{
  max-width: 100%;
  max-height:100%;
}

.spt_hd_btn_img{
  width:2.5rem;
  max-height:2.5rem;
  border-radius: 2.5rem;
  -moz-border-radius: 2.5rem;;
  -webkit-border-radius: 2.5rem;;
  margin-left: .5rem;
  margin-right: .5rem;
  border-style: solid;
  cursor: pointer;
}
.spt_hd_btn_img:hover{
  filter:brightness(1.25);
  transition:.5s;
}

/****FORMS*/
.support_input_prepend{
  background-color: var(--lightshade);
  padding-left: .25rem;
  padding-right: .25rem;
  display: flex;
  align-items: center;
  border-top-left-radius:.25rem;
  border-bottom-left-radius:.25rem;
}

.support_forms_align_right{
  text-align: right;
}



.alert{
  position:absolute;
  z-index:4;
  margin:1rem;
  top:0px;
}

/*****NAVIGATION*/
.support_navigation_main{
  padding-top:0.25rem;
  padding-bottom: 1rem;
  color: var(--lighttext)
}

.support_navigation_title{
  padding-top:.5rem;
  padding-bottom: .5rem;
  text-transform: uppercase;
}

.support_navigation_item_selected{
  background-color: var(--lightshade);
  width: 100%;
  color:var(--darktext);
  font-weight: 900;
  padding-top:.5rem;
  padding-left:.75rem;
  padding-bottom: .5rem;
  cursor: pointer;
  transition: 1s;
}

.support_navigation_item_unselected{
  width: 100%;
  padding-top:.5rem;
  padding-left:.25rem;
  padding-bottom: .5rem;
  cursor: pointer;
  transition: 1s;
  color:var(--lighttext);
}

.support_navigation_item_unselected:hover{
  font-weight: 900;
}

.support_navigation_mega{
  padding:.5rem;
  text-transform: uppercase;
  display:flex;
}

.spt_hori_navigation{
  color:var(--primary);
  display: flex;
}

.spt_hori_navigation_selected{
  background-color: var(--darkshade);
  flex:1.10;
  color:var(--lighttext);
  padding-top:.5rem;
  padding-left:.25rem;
  padding-bottom: .5rem;
  margin-left:.10rem;
  margin-right:.10rem;
  cursor: pointer;
  transition: .5s;
  font-weight: 900;
}

.spt_hori_navigation_unselected{
  background-color: var(--white);
  flex:1;
  color:var(--darktext);
  padding-top:.5rem;
  padding-left:.25rem;
  padding-bottom: .5rem;
  margin-left:.10rem;
  margin-right:.10rem;
  cursor: pointer;
  transition: .5s;
}




.support_range{
	width: 100%;
  text-align: center;
  margin-top: .25rem;
}

.support_range > input[type="range"]{
  -webkit-appearance: none;
  padding: .25rem;
  width: 95%;
}

.support_range > input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: var(--primary);
  border-color: var(--primary);
  cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  background: var(--primary);
  border-color: var(--primary);
  cursor: pointer;
}
input[type=range]::-ms-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  background: var(--primary);
  border-color: var(--primary);
  cursor: pointer;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: linear-gradient(90deg, var(--danger) 15%,var(--lightshade) 50%, var(--success) 85%);
  border-radius: 2px;
  border-width: 2px;
}
input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  width: 100%;
  cursor: pointer;
  background: linear-gradient(90deg, var(--danger) 15%,var(--lightshade) 50%, var(--success) 85%);
  border-radius: 2px;
  border-width: 2px;
}
input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: linear-gradient(90deg, var(--danger) 15%,var(--lightshade) 50%, var(--success) 85%);
  border-radius: 2px;
  border-width: 2px;

}


.support_range_title{
  background-color: var(--primary);
}

.support_range_label{
	width: 14.28%;
  text-align: center;
  color: var(--darktext);
}


/******BUY BUTTONs****/
.spt_bb_wrapper{
  flex:1;
  margin:1rem;
  background-color: var(--primary);
  padding:1rem;
  text-align: center;
  cursor: pointer;
  color: var(--lighttext);
}
.spt_bb_wrapper:hover{
  filter:brightness(1.25);
  transition:.5s;
}

.spt_bb_pricer{
  font-weight: 900;
  font-size: 3rem;
}

.spt_bb_wrapper_disabled{
  flex:1;
  margin:1rem;
  background-color: var(--lightaccent);
  padding:1rem;
  text-align: center;
  color: var(--darktext);
}



/*******TABS******/
.spt_tabs_element{
  padding:1rem .5rem;
  flex-grow: 1;
  flex-basis: auto;
  cursor:pointer;
}

.spt_tabs_rows{
  width:100%;
  margin-top:1rem;
  display:flex;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: var(--darkaccent);
}