.edit_profile_avatar{
  width: 100%;
  height: auto;
}

.edit_profile_avatar_row{
  margin-bottom: 0.75rem;
}

.edit_profile_box1{
  margin-top: 1rem;
  margin-bottom:1rem;
}

.edit_profile_customization{
  margin-top:2rem;
}

.edit_profile_main_placer{
  margin-top:1rem;
  margin-bottom:1rem;
}

.edit_profile_password_area{
  width:100%;
  border-color:var(--primary);
  border-style: solid;
  border-radius: .5rem;

  transition: height 1s;
}

.edit_profile_delete_user_area{
  background-color: rgba(241, 139, 139, 0.986);
  padding:1rem;
}

.edit_profile_delete_user_submit{
  margin-top:0.5rem;
  text-align: right;;
}

.edit_profile_password_doesnot_match{
  border-radius: 0.25rem;
  padding:1rem;
  text-align: center;
  font-weight: 900;
  background-color: var(--danger);
  margin-bottom: 1.5rem;
}

.edit_profile_password_match{
  border-radius: 0.25rem;
  padding: 1rem;
  text-align: center;
  font-weight: 900;
  background-color: var(--success);
  margin-bottom: 1.5rem;
}

.edit_profile_picture{
  min-height: 5rem;
  min-height: 5rem;
  max-height: 15rem;
  object-fit: cover;
}

.edit_profile_save_password_button{
  margin-top:1rem;
  margin-bottom: 1rem;
}

.edit_profile_single_input_forms{
  margin-top: 0.75rem;
}

.profile_password_col{
  padding:1rem;
}
