.template_image{
  width: 100%;
  height: auto;
}

.template_row{
  margin-bottom: 0.75rem;
  color: var(--primary);
  text-align: center;
}

.template_options_h5{
  margin-top: 1.5rem;
}

.template_options_h6{
  margin-top: 1rem;
}

.export_button{
  margin-top: 1rem;
}