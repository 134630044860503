/* //PROJECT ADD//  */

.add_project_major_row{
  margin-top:1rem;
  margin-bottom:0.5rem;
}


/*/////////*/
/* PROJECT ADD VERSION */
/*/////////*/

.project_add_version_collapse{
  padding-top: 1rem;
  padding-bottom: .5rem;
}

.add_project_buy_btn_wrapper{
  display:flex;
  flex-wrap: wrap;
}

.add_project_buy_btn{
  flex:1;
  text-align: center;
  background-color: var(--lightaccent);
  margin:1rem;
}

.add_project_cost_wrapper{
  width: 100%;
  padding:2rem 2rem;
  background-color: var(--lightaccent);
}



/*/////////*/
/*PROJECT LIST*/
/*/////////*/
.project_list_add_new{
  margin: 2rem 0rem;
}

.project_list_header{
  background-color: var(--darkaccent);
  color: white;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.project_list_project_list{
  padding-bottom: 0.25rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid;
  border-bottom-color: var(--darkaccent); 
}

/*PROJECT Main*/
.project_main_placer{
  margin-top:1rem;
  margin-bottom: 1rem;
}

/*PROJECT EDIT */
.project_edit_card{
  margin-bottom: 1rem;
}

.project_edit_card_header{
  font-weight: 700;
  
}

.project_edit_card_body{
  text-align: center;
}

.project_edit_settings_row{
  margin-top:1.5rem;
  margin-bottom: 1.5rem;
  width:100%
}

.project_edit_gauge{
  margin-top:0.75rem;
  padding-bottom: .5rem;
  width:100%;
  background-color: var(--lightaccent);
  color: var(--darktext);
  margin-bottom: 1rem;
  text-align: center;
}

.project_edit_gauge_main{
  margin-top: 1rem;
  margin-bottom:1rem;
  margin-left: 15%;
  width:70%;
  min-height: 1.5rem;
  border-radius: .15rem;
  position: relative;
  background-color: var(--white);
}

.project_edit_gauge_progress{
  background-color: var(--darkaccent);
  height:100%;
  font-size: large;
  border-radius: .15rem;
  position: absolute;
  top:0px;
  z-index: 1;
}

.project_edit_gauge_t1{
  padding-top: .25rem;
  padding-bottom: .25rem;
}
.project_edit_gauge_t2{
  font-size: xx-large;
  font-weight: 900;
}

.feasibility_quick_add_button{
  margin-top: .75rem;
  margin-bottom: .75rem;
}

