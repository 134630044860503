/*MISCELANEOUS*/
.canva_exportbutton{
  margin-bottom:1rem;
}
.canva_header_row{
  margin-top:0.5rem;
  margin-bottom:1rem;
}

.canva_swot_items{
  text-align: center;
}

.canva_swot_text_prepend{
  padding:0;
}

.canva_swot_swot_rows{
  margin:0.5rem 0rem;
  background-color:var(--lightshade);
  padding: 0.5rem 0rem;
  border-radius: 0.25rem;
}

.canva_align_right{
  text-align: right;
  display:flex;
}
/*PRESTEL*/
.canva_pestel_box{
  background-color: var(--white);
}


/*BMC */
.canva_bmc_2row{
  margin-top:2rem;
  margin-bottom: 2rem;
}

.canva_bmc_options_row{
  margin-top: 1rem;
  width:100%;
  display: flex;
  flex-wrap:wrap;
  background-color: var(--lightshade);
  border-radius: 0.25rem;
}

.canva_bmc_button_20{
  width:20%;
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.canva_bmc_button_100{
  width:50%;
  height:2.5rem;
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.canva_bmc_key{
  background-color:white;
  border:solid;
  border-width: 1px;
  box-shadow: inset 0px 0px 4px coral;
  border-color: coral;
  color: slategrey;
}

.canva_bmc_key_active{
  background-color: coral;
  border:solid;
  border-color:  rgba(0, 0, 0, 0.5);
  font-weight: 900;
  color:white;
}
.canva_bmc_value{
  background-color:white;
  box-shadow: inset 0px 0px 4px cornflowerblue;
  border:solid;
  border-width: 1px;
  border-color:  cornflowerblue;
  color: slategrey;
}

.canva_bmc_value_active{
  background-color: cornflowerblue;
  border:solid;
  border-color:  rgba(0, 0, 0, 0.5);
  font-weight: 900;
  color:white;
}

.canva_bmc_client{
  background-color:white;
  box-shadow: inset 0px 0px 4px darkolivegreen;
  border:solid;
  border-width: 1px;
  border-color:  darkolivegreen;
  color: slategrey;
}
.canva_bmc_client_active{
  background-color: darkolivegreen;
  border:solid;
  border-color:  rgba(0, 0, 0, 0.5);
  font-weight: 900;
  color:white;
}

.canva_bmc_finance{
  background-color:white;
  box-shadow: inset 0px 0px 4px palevioletred;
  border:solid;
  border-width: 1px;
  border-color:  palevioletred;
  color: slategrey;
}

.canva_bmc_finance_active{
  background-color: palevioletred;
  border:solid;
  border-color:  rgba(0, 0, 0, 0.5);
  font-weight: 900;
  color:white;
}

.canva_bmc_buttons{
  width:100%;
}

.canva_bmc_box_20{
  width:20%;
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.canva_bmc_box_50{
  width:50%;
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.canva_bmc_box{
  width:100%;
  padding:2%;
  margin:0.15rem;
}
.canva_bmc_box_list{
  margin-bottom:0.25rem;
}