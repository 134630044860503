.spt_table{
  width:100%;
  background-color: var(--lightaccent);
}
  

.spt_table_header{
  padding:.25rem;
  border-style: solid;
  border-color: var(--darkaccent);
  color: var(--darktext);
  border-width: 2px;
  text-align: center;
}

.spt_table_cell{
  padding:.2rem;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: var(--darkaccent);
  text-align: right;
}

.spt_table_first_cell{
  padding:.2rem;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: var(--darkaccent);
  text-align: left;
}