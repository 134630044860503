/*HEADERBAR */
.headerbar_row{
  background-color: var(--darkshade);
  color:var(--lighttext);
  text-transform: uppercase;
  font-size: 12px;
  padding-top: .2rem;
  padding-bottom: .2rem;
}

.headerbar_buttons_position{
  text-align: right;
  display: flex;
}

/*Feasibility Pane*/
.headerbar_panes{
  background-color: var(--white);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  margin-bottom: 1rem;
}

.pane_navigator{
  padding-top:0.25rem;
  padding-bottom: 1rem;
  background-color: var(--darkshade);
}

.pane_content{
  padding-bottom: 1rem;
}

.pane_navigator2{
  background-color:var(--lightaccent);
}

.pane_navigator2 a{
  color:var(--darkaccent);
  font-weight: 700;
}

.feasibility_navigation_row{
  width:100%;
  display:flex;
  border:solid;
}
.feasibility_navigation_image{
  width:25%;
}
.feasibility_navigation_2{
  width:10%;
  background-color:var(--warning);
  height:10%;
  display:flex;
  align-items: center;
}
.feasibility_navigation_1{
  width:90%;
}