/*HEADERBAR */
.feasibility_headerbar_row{
  background-color: var(--darkshade);
  color:white;
  padding: 0.5rem 0rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 12px;
}

.feasibility_headerbar_buttons_position{
  text-align: right;
}

/*Feasibility Pane*/
.feasibility_headerbar_panes{
  /*box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);**/
  margin-bottom: 1rem;
}

.feasibility_pane_navigator{
  padding-top:0.25rem;
  padding-bottom: 0.25rem;
  border-right:solid;
  border-right-width: 1px;
  border-right-color: var(--darkaccent);
}

.feasibility_pane_navigator2{
  background-color:var(--lightaccent);
}

.feasibility_pane_navigator2 a{
  color:var(--darkaccent);
  font-weight: 700;
}

/*TO BE USED*/
.year_header {
  background-color: var(--darkaccent);
  color: green;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  font-weight:700;
}

.year_header_edit{
  text-align: center;
  border-left:solid;
  border-left-color:white;
  border-left-width:1px;
}

.feasibility_edit_globalheader_row{
  margin-top:0.5rem;
}

.feasibility_middle_row{
  margin-top: 1rem;
}

.non_editable_list {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--darkaccent);
  padding-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.feasibility_edits_break_row{
  margin-top: 2rem;
  margin-bottom: 1rem;
}


/*REVENUES*/
.feasibility_revenues_add_item_cogs{
  margin-top:0.5rem;
  margin-bottom:1rem;
}

/*FEASIBILITY INPUTS */
.feasibility_feasibility_rows{
  margin-top:0.5rem;
  margin-bottom:0.5rem;
}

.feasibility_feasibility_main_rows{
  margin-top:1.5rem;
  margin-bottom:1.5rem;
}

/*MAIN DASHBOARD GRAPHS*/
.feasibility_dashboard_header_row{
  margin-top:0.5rem;
  margin-bottom:1.5rem;
}
.feasiblity_dashboard_graphs_row{
  margin-top:1rem;
  margin-bottom: 1rem;
}

/*HISTORIC */
.hist_list{
  margin-top: 3rem;
}

.hist_support_table{
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-bottom-style: solid;
  border-bottom-color: var(--darkaccent);
}

.hist_support_table_danger{
  background-color: var(--danger);
}

.hist_analysis_h5{
  margin-top: 1.5rem;
}

.hist_analysis_rows{
  border-bottom: solid;
  border-width:1px;
  border-color:var(--primary)
}
