.brand_background{
  background-image: url("../Images/bk.png");
  background-size:cover;
  background-repeat: no-repeat, repeat;
  background-position: center;
  min-height: 550px;
}

.intro_button{
  min-height: 6rem;
  font-size: 1.25rem;
  color:#183641;
  font-weight:900;
  padding:1rem;
  margin:1rem 0rem;
}

.brand_info{
  width:100%;
  text-align: center;
  padding-top:8rem;
  padding-bottom:8rem;
}

.brand_social_message{
  margin-top:1.5rem;
  margin-bottom: 2.5rem;
  text-align: center;
  color: var(--primary);
}

