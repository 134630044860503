.subscription_placer{
  padding-top: 1rem;
  padding-bottom: 1rem;

}

.subscription_buy_button_row{
  padding-bottom: 1rem;
}

.subscription_buy_box1{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.subscription_compare_button_row{
  margin-top:1.5rem;
  margin-bottom:1rem;
}

.subsciption_buy_options_row{
  margin-bottom:2rem;
}

.subscription_buy_options_cells_row{
  margin-bottom:0.5rem;
}

.subscription_buy_options_cells_col{
  text-align:center;
}

.subscription_buy_options_main_row{
  background-color: var(--lightaccent);
  color:white;
  padding:0.25rem;
  margin-bottom:0.5rem;
  margin-top:1rem;
  border-radius: 0.25rem;
}

.subscription_buy_compate_features_header{
  background-color:var(--darkaccent);
  color:white;
  text-align:center;
  padding:1rem;
  border-radius: 0.25rem;
}



/*SUBSCRIPTION MANAGE */
.subscription_manage_blocks{
  margin-bottom: 1rem;
  margin-top:1rem;
}

.subscription_manage_details{
  padding-bottom: 1.5rem;
}

.subscription_manage_extension{
  margin-bottom: 1rem;
}


/* SUBSCRITPION IN PROJECT*/
.subscription_in_project_h5{
  margin-top: 1rem;
}

.subscription_in_project_list_items{
  margin-top: 0.15rem;
  border-bottom:solid;
  border-bottom-color: var(--lightaccent);
  border-bottom-width: 1px;
}

.subscription_project_button{
  margin-top: 0.5rem;
}

.subscription_project_sign{
  width:100%;
  color:white;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 2px;
  border-color:white;
  margin-top: 0.5rem;
  margin-bottom:0.5rem;
  font-weight: 700;
}

/*SUBSCRIPTIONS LIST*/
.subscription_list_subscription_list{
  padding-bottom: 0.25rem;
  margin-bottom: 1.5rem;
}

.subscriptions_list_header{
  background-color: var(--darkaccent);
  color: white;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.subscriptions_list_item{
  margin-top:0.5rem;
  margin-bottom:0.5rem;
  padding-bottom: .5rem;
  border-color: var(--darkaccent);
  border-bottom-style: solid;
  border-width: 1px;
}


/*THANK YOU*/
.subscription_thank_u_h1{
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  font-size: 3rem;
}



/* STRIPE STYLES */
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}